module.exports = {
  siteTitle: 'Marisela Espinoza', // <title>
  manifestName: 'Prologue',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-prologue/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Marisela',
  heading: 'Software Engineer',
  // social
  socialLinks: [
    {
      icon: 'fa-id-card',
      name: 'Resume',
      url: 'https://drive.google.com/file/d/1_DrhRjg4P3pNHdIqfEeSXI3Oz4Jo626n/view?usp=sharing',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:m.espinoza16@hotmail.com',
    },
    {
      icon: 'fa-linkedin-square',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/marisela-espinoza/',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/mespinoza30',
    },
  ],
};
